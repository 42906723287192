var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Card",
    { staticClass: "person-info", staticStyle: { position: "relative" } },
    [
      _c(
        "Form",
        { staticClass: "tCheckStationForm", attrs: { "label-width": 82 } },
        [
          _c(
            "Row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showInfo,
                  expression: "showInfo",
                },
              ],
            },
            [
              _c(
                "Col",
                {
                  staticStyle: { flex: "0 0 150px", "max-width": "unset" },
                  attrs: { span: 3 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "pic", staticStyle: { width: "150px" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pic-box",
                          staticStyle: { "margin-right": "0" },
                        },
                        [
                          _vm.info.avatar
                            ? _c("img", {
                                directives: [
                                  { name: "viewer", rawName: "v-viewer" },
                                ],
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: { src: _vm.imgSrc },
                              })
                            : _c("img", {
                                staticStyle: {
                                  width: "100%, height: 100%",
                                  padding: "5px",
                                },
                                attrs: {
                                  src: require("../../../../assets/user-avatar.png"),
                                },
                              }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "Col",
                {
                  staticClass: "form",
                  staticStyle: {
                    "max-width": "calc(100% - 150px)",
                    "justify-content": "flex-start",
                  },
                  attrs: { span: 21 },
                },
                [
                  _c(
                    "Row",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c(
                        "Col",
                        { attrs: { span: 24 } },
                        [_c("step", { attrs: { index: 2 } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "Row",
                    { staticStyle: { "margin-top": "10px" } },
                    _vm._l(_vm.infoCols, function (item) {
                      return _c(
                        "Col",
                        { key: item.label, attrs: { span: item.span } },
                        [
                          _c(
                            "form-item",
                            { attrs: { label: item.label } },
                            [
                              item.tooltip
                                ? _c(
                                    "Tooltip",
                                    _vm._b(
                                      { attrs: { content: item.content } },
                                      "Tooltip",
                                      item.tooltip,
                                      false
                                    ),
                                    [_vm._v(_vm._s(item.content))]
                                  )
                                : _c("span", [_vm._v(_vm._s(item.content))]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.$slots["buttons"]
            ? _c(
                "div",
                {
                  style: `display: flex; margin-top: ${
                    _vm.showInfo ? 10 : 0
                  }px`,
                },
                [_vm._t("buttons")],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }